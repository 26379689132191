import { Colors, FontFamily } from "@/styles/styles";

export type HeaderTheme = {
  background: {
    loggedIn: string;
    loggedOut: string;
  };
  padding: string;
  margin: string;
  color: string;
  fontFamily: {
    headline: string;
    paragraph: string;
  };
  fontSize: {
    headline: string;
    paragraph: string;
  };
  lineHeight: string;
  textTransform: string;
  highlightColor: string;
};

const defaultHeaderTheme: HeaderTheme = {
  background: {
    loggedIn: Colors.White,
    loggedOut: Colors.White,
  },
  padding: "16px",
  margin: "16px",
  color: Colors.Black,
  fontFamily: {
    headline: FontFamily.MarkOTHeavy,
    paragraph: FontFamily.MarkOTRegular,
  },
  fontSize: {
    headline: "16px",
    paragraph: "14px",
  },
  lineHeight: "21px",
  textTransform: "normal",
  highlightColor: Colors.White,
};

const weltHeaderTheme: HeaderTheme = {
  ...defaultHeaderTheme,
  background: {
    loggedIn: "linear-gradient(9.82deg, #165b8d 32.18%, #003a5a 79.21%)",
    loggedOut: Colors.WeltBlue,
  },
  color: Colors.White,
  fontFamily: {
    headline: FontFamily.MarkOTHeavy,
    paragraph: FontFamily.MarkOTRegular,
  },
  textTransform: "uppercase",
  highlightColor: Colors.WeltOrange,
};

export { defaultHeaderTheme, weltHeaderTheme };
