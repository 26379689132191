import { Colors, FontFamily } from "@/styles/styles";

export type ShowAllButtonTheme = {
  fontFamily: string;
  fontSize: string;
  color: string;
  marginTop: string;
  height: string;

  hover: {
    color: string;
  };
};

const defaultShowAllButtonTheme: ShowAllButtonTheme = {
  fontFamily: FontFamily.Gotham,
  fontSize: "12px",
  marginTop: "15px",
  color: Colors.White,
  height: "30px",
  hover: {
    color: Colors.White,
  },
};

const weltShowAllButtonTheme: ShowAllButtonTheme = {
  ...defaultShowAllButtonTheme,
  fontFamily: FontFamily.MarkOTHeavy,
  fontSize: "14px",
  marginTop: "16px",
  color: Colors.White,
  height: "20px",
  hover: {
    color: Colors.WeltLightGrey,
  },
};

export { defaultShowAllButtonTheme, weltShowAllButtonTheme };
