import styled from "styled-components";

import { fadeInAnimation } from "@/styles/common";

export const StyledNewsletterCard = styled.div`
  width: 100%;
  height: 98px;
  background: ${(props) => props.theme.HeaderNewsletterCard.backgroundColor};
  flex-shrink: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;

  &.fadeIn {
    animation: ${fadeInAnimation} 0.5s ease-in-out;
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 463px;
  }
`;

type CardImageProps = {
  src: string;
};
export const StyledCardImage = styled.div<CardImageProps>`
  width: 160.3px;
  height: 76.65px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;
  flex-shrink: 0;
`;

export const StyledNewsletterCardTitle = styled.p`
  font-size: ${(props) => props.theme.HeaderNewsletterCard.title.fontSize};
  font-family: ${(props) => props.theme.HeaderNewsletterCard.title.fontFamily};
  color: ${(props) => props.theme.HeaderNewsletterCard.title.color};
  line-height: ${(props) => props.theme.HeaderNewsletterCard.title.lineHeight};
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 0;
`;

export const StyledNewsletterCardTransmissionTime = styled.p`
  font-size: ${(props) =>
    props.theme.HeaderNewsletterCard.transmissionTime.fontSize};
  font-family: ${(props) =>
    props.theme.HeaderNewsletterCard.transmissionTime.fontFamily};
  color: ${(props) => props.theme.HeaderNewsletterCard.transmissionTime.color};
  line-height: ${(props) =>
    props.theme.HeaderNewsletterCard.transmissionTime.lineHeight};
  text-transform: uppercase;
  text-align: left;

  @media ${(props) => props.theme.global.breakpoints.mobileSmall} {
    display: none;
  }
`;

export const StyledNewsletterCardContent = styled.div`
  margin-left: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
