import styled from "styled-components";

import { expandAnimation, flexCenterCenterColumn } from "@/styles/common";

type StyledNewsletterCardWrapperProps = {
  showAll: boolean;
};

export const StyledHeader = styled.div`
  background-color: ${(props) => props.theme.Header.background.loggedOut};
  ${flexCenterCenterColumn}
  min-height: 207px;
  margin: 0 -15px;

  &.loggedIn {
    background: ${(props) => props.theme.Header.background.loggedIn};
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    margin: 0 -30px;
  }
`;

export const StyledHeaderContentLoggedInWrapper = styled.div`
  padding: ${(props) => props.theme.Header.padding};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    width: 970px;
  }
`;

export const StyledHeaderContentWrapper = styled.div`
  max-width: 420px;
  padding: ${(props) => props.theme.Header.padding};

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    padding: 0;
  }
`;

export const StyledHeaderHeadline = styled.h3`
  color: ${(props) => props.theme.Header.color};
  font-family: ${(props) => props.theme.Header.fontFamily.headline};
  font-size: ${(props) => props.theme.Header.fontSize.headline};
  line-height: ${(props) => props.theme.Header.lineHeight};
  text-transform: uppercase;
`;

export const StyledHeaderParagraph = styled.p`
  color: ${(props) => props.theme.Header.color};
  font-family: ${(props) => props.theme.Header.fontFamily.paragraph};
  font-size: ${(props) => props.theme.Header.fontSize.paragraph};
  line-height: ${(props) => props.theme.Header.lineHeight};
  margin: ${(props) => props.theme.Header.margin} 0;
`;

export const StyledBoldSpan = styled.span`
  font-family: ${(props) => props.theme.Header.fontFamily.headline};
`;

export const StyledNewsletterCardWrapper = styled.div<StyledNewsletterCardWrapperProps>`
  animation: ${(props) => props.showAll && expandAnimation(280)} 0.5s
    ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  gap: 8px;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    animation: ${(props) => props.showAll && expandAnimation(160)} 0.5s
      ease-in-out;
  }
`;

export const StyledSubscribedNewsletterCounter = styled.span`
  display: inline-block;
  background-color: ${(props) => props.theme.Header.highlightColor};
  color: ${(props) => props.theme.Header.color};
  font-size: 11px;
  line-height: ${(props) => props.theme.Header.lineHeight};
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  vertical-align: bottom;
  margin-left: 4px;
`;
