import styled from "styled-components";

export const StyledHeadline = styled.h1`
  padding: ${(props) => props.theme.Headline.padding.medium} 0;
  color: ${(props) => props.theme.Headline.color};
  font-family: ${(props) => props.theme.Headline.fontFamily};
  font-size: ${(props) => props.theme.Headline.fontSize};
  line-height: ${(props) => props.theme.Headline.lineHeight};

  text-align: center;
  text-transform: uppercase;

  &.m {
    padding: ${(props) => props.theme.Headline.padding.medium} 0;
  }

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    padding: ${(props) => props.theme.Headline.padding.xl} 0;
  }
`;
