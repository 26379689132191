import { Colors, FontFamily } from "@/styles/styles";

export type NewsletterBannerTheme = {
  backgroundColor: string;
  borderRadius: string;
  lineHeight: string;
  fontSize: string;
  fontFamily: string;
  color: string;
  marginRight: string;
};

const defaultNewsletterBannerTheme: NewsletterBannerTheme = {
  backgroundColor: Colors.White,
  borderRadius: "3px",
  lineHeight: "14px",
  fontSize: "11px",
  fontFamily: FontFamily.Gotham,
  color: Colors.DarkBlack,
  marginRight: "8px",
};

const weltNewsletterBannerTheme: NewsletterBannerTheme = {
  ...defaultNewsletterBannerTheme,
  fontFamily: FontFamily.MarkOTHeavy,
};

export { defaultNewsletterBannerTheme, weltNewsletterBannerTheme };
