import { Colors, FontFamily } from "@/styles/styles";

export type LinkTheme = {
  color: string;
  fontFamily: {
    small: string;
    default: string;
  };
  fontSize: {
    small: string;
    default: string;
  };
  lineHeight: {
    small: string;
    default: string;
  };
};

const defaultLinkTheme: LinkTheme = {
  color: Colors.Black,
  fontFamily: {
    small: "Arial",
    default: "Arial",
  },
  fontSize: {
    small: "11px",
    default: "14px",
  },
  lineHeight: {
    small: "14px",
    default: "21px",
  },
};

const weltLinkTheme: LinkTheme = {
  ...defaultLinkTheme,
  color: Colors.White,
  fontFamily: {
    small: FontFamily.MarkOTExtraLight,
    default: FontFamily.MarkOTRegular,
  },
};

export { defaultLinkTheme, weltLinkTheme };
