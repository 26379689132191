import { Colors, FontFamily, IGlobal } from "../styles";
import { breakpoints } from "../themes/welt";

const ssoAppDefaultGlobalTheme: IGlobal = {
  breakpoints: breakpoints,
  background: Colors.Transparent,
  colors: {
    black: Colors.DarkBlack,
  },
  fonts: {
    fontFamily: FontFamily.MarkOTRegular,
    fontFamilyCondensed: FontFamily.MarkOTExtraLight,
    fontFamilyExtra: FontFamily.MarkOTHeavy,
  },
  transitions: {
    duration: "100ms",
    speedCurve: "ease-in",
  },
};

// define global styles for each SSO brand here
const weltGlobalTheme: IGlobal = {
  breakpoints: breakpoints,
  background: Colors.Transparent,
  colors: {
    black: Colors.DarkBlack,
  },
  fonts: {
    fontFamily: FontFamily.MarkOTRegular,
    fontFamilyCondensed: FontFamily.MarkOTExtraLight,
    fontFamilyExtra: FontFamily.MarkOTHeavy,
  },
  transitions: {
    duration: "100ms",
    speedCurve: "ease-in",
  },
};

export { ssoAppDefaultGlobalTheme, weltGlobalTheme };
