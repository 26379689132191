import { WhoamiV1 } from "@spring-media/ps-web-apis";
import { WhoamiWrapper } from "@weltn24/la-frontend-utils";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import * as Configs from "@/brandConfig";
import Brands from "@/Brands";
import DevelopmentInfo from "@/components/DevelopmentInfo";
import ErrorBoundary from "@/components/ErrorBoundary";
import * as Contents from "@/content";
import { DefaultContent } from "@/content/Model";
import { AppFactoryProps } from "@/factory";
import { HandleProps } from "@/factory/AppFactory";
import { isProduction } from "@/services/Environment";
import { trackRender } from "@/services/Tracking";
import { createStore } from "@/store";
import GlobalStyles, { IFontFamily } from "@/styles/GlobalStyles";
import { BrandThemes } from "@/styles/SSOThemes";
import Registration from "@/views/Registration";

// create global sso context - set welt content as default
export const ContentContext = React.createContext<DefaultContent>(
  Contents[Brands.Welt],
);

export const ConfigContext = React.createContext(Configs[Brands.Welt]);

const App: React.FC<AppFactoryProps> = (props): JSX.Element => {
  const { brand } = props;
  const fonts = brand as keyof IFontFamily;

  const theme = BrandThemes[brand];
  const content = Contents[brand] as DefaultContent;
  const brandConfig = Configs[brand];

  const [whoami, setWhoami] = useState<WhoamiV1>();

  useEffect(() => {
    const initWhoami = async () => {
      await WhoamiWrapper.init().then(() => {
        setWhoami(WhoamiWrapper.getInstance());
        trackRender();
      });
    };

    initWhoami().catch(() => console.error("Error while init Whoami"));
  }, []);

  return whoami ? (
    <Provider store={createStore()}>
      <ThemeProvider theme={theme}>
        <ConfigContext.Provider value={brandConfig}>
          <ContentContext.Provider value={content}>
            <GlobalStyles fonts={fonts} />
            <ErrorBoundary>
              <>
                <Registration />
                {isProduction() || <DevelopmentInfo />}
              </>
            </ErrorBoundary>
          </ContentContext.Provider>
        </ConfigContext.Provider>
      </ThemeProvider>
    </Provider>
  ) : (
    <p>Laden ...</p>
  );
};

const factoryHandler: HandleProps = {
  canHandle: (props) =>
    [Brands.Welt, Brands.SSOAppDefault].includes(props.brand),
  renderComponent: (props) => <App brand={props.brand} />,
};

export default {
  ...App,
  ...factoryHandler,
};
