import styled from "styled-components";

export const StyledShowAllButton = styled.button`
  display: inline;
  font-family: ${(props) => props.theme.ShowAllButton.fontFamily};
  font-size: ${(props) => props.theme.ShowAllButton.fontSize};
  color: ${(props) => props.theme.ShowAllButton.color};
  background-color: transparent;
  border: none;
  margin-top: ${(props) => props.theme.ShowAllButton.marginTop};
  height: ${(props) => props.theme.ShowAllButton.height};
  width: 100%;

  text-transform: uppercase;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.ShowAllButton.hover.color};
    }
  }
`;
