import { DefaultTheme } from "styled-components";

import { defaultChipTheme } from "@/components/Chip/theme";
import { defaultDialogTheme } from "@/components/Dialog/theme";
import { defaultHeadlineTheme } from "@/components/Headline/theme";
import { defaultLinkTheme } from "@/components/Link/theme";
import { defaultNewsletterBannerTheme } from "@/components/NewsletterBanner/theme";
import { defaultShowAllButtonTheme } from "@/components/ShowAllButton/theme";
import { defaultSmartCTAButtonTheme } from "@/components/SmartCTAButton/theme";
import { defaultButtonTheme } from "@/components/StyledButton/theme";
import { defaultTopButtonTheme } from "@/components/TopButton/theme";
import { defaultFeaturedNewsletterCardTheme } from "@/sections/FeaturedNewsletter/FeaturedNewsletterCard/theme";
import { defaultHeaderNewsletterCardTheme } from "@/sections/Header/HeaderNewsletterCard/theme";
import { defaultHeaderTheme } from "@/sections/Header/theme";
import { defaultTopicNewsletterCardTheme } from "@/sections/TopicNewsletter/TopicNewsletterCard/theme";

import { ssoAppDefaultGlobalTheme } from "./globalStyles";

const ssoAppDefault: DefaultTheme = {
  global: ssoAppDefaultGlobalTheme,
  Header: defaultHeaderTheme,
  Headline: defaultHeadlineTheme,
  Link: defaultLinkTheme,
  HeaderNewsletterCard: defaultHeaderNewsletterCardTheme,
  TopicNewsletterCard: defaultTopicNewsletterCardTheme,
  NewsletterBanner: defaultNewsletterBannerTheme,
  Button: defaultButtonTheme,
  ShowAllButton: defaultShowAllButtonTheme,
  Chip: defaultChipTheme,
  FeaturedNewsletterCard: defaultFeaturedNewsletterCardTheme,
  SmartCTAButton: defaultSmartCTAButtonTheme,
  TopButton: defaultTopButtonTheme,
  Dialog: defaultDialogTheme,
} as DefaultTheme;

export { ssoAppDefault };
