import { Colors } from "@/styles/styles";

export type DialogTheme = {
  backgroundColor: Colors;
};

const defaultDialogTheme: DialogTheme = {
  backgroundColor: Colors.Grey,
};

const weltDialogTheme: DialogTheme = {
  backgroundColor: Colors.WeltBlue,
};

export { defaultDialogTheme, weltDialogTheme };
