import styled from "styled-components";

import { Colors } from "@/styles/styles";

export const StyledTopicNewsletterContainer = styled.section`
  max-width: 910px;
  margin: 0 auto 10px;
`;

export const StyledTopicNewsletterSection = styled.div`
  border-top: 1px solid ${Colors.WeltLightGrey};
  margin: 0 calc(-2 * 16px);
  padding: 0 calc(2 * 16px);

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    padding: 0 16px calc(2 * 25px) 16px;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-basis: content;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 30px;
  row-gap: 16px;

  @media (min-width: 909px) {
    justify-content: unset;
    row-gap: 30px;
    padding: 0 16px;
  }
`;
