import { Colors } from "@/styles/styles";

export type FeaturedNewsletterCardTheme = {
  background: string;
  padding: string;
  borderRadius: string;
};

const defaultFeaturedNewsletterCardTheme: FeaturedNewsletterCardTheme = {
  background: Colors.White,
  padding: "16px",
  borderRadius: "8px",
};

const weltFeaturedNewsletterCardTheme: FeaturedNewsletterCardTheme = {
  ...defaultFeaturedNewsletterCardTheme,
};

export { defaultFeaturedNewsletterCardTheme, weltFeaturedNewsletterCardTheme };
