import { Colors } from "@/styles/styles";

export type TopButtonTheme = {
  backgroundColor: {
    mobile: Colors;
    desktop: {
      hover: Colors;
    };
  };
  border: Colors;
};

const defaultTopButtonTheme: TopButtonTheme = {
  backgroundColor: {
    mobile: Colors.White,
    desktop: {
      hover: Colors.WeltLightGrey,
    },
  },
  border: Colors.WeltOrange,
};

const weltTopButtonTheme: TopButtonTheme = {
  ...defaultTopButtonTheme,
  border: Colors.WeltLightGrey,
};

export { defaultTopButtonTheme, weltTopButtonTheme };
