import styled from "styled-components";

import { fadeInAnimation, fadeOut } from "@/styles/common";

export const Button = styled.button`
  display: inline-block;
  width: 100%;
  max-width: ${(props) => props.theme.Button.maxWidth.mobile};
  height: ${(props) => props.theme.Button.height};
  border-width: ${(props) => props.theme.Button.border.width};
  border-style: ${(props) => props.theme.Button.border.style};
  border-color: ${(props) => props.theme.Button.border.color};
  border-radius: ${(props) => props.theme.Button.border.radius};
  background-color: transparent;
  color: ${(props) => props.theme.Button.color};

  font-family: ${(props) => props.theme.Button.fontFamily};
  font-size: ${(props) => props.theme.Button.fontSize};
  text-align: center;
  text-transform: uppercase;
  transition: all 0.15s ease-in-out;

  @media ${(props) => props.theme.global.breakpoints.desktop} {
    max-width: ${(props) => props.theme.Button.maxWidth.desktop};

    &:hover {
      background-color: ${(props) => props.theme.Button.hover.backgroundColor};
      color: ${(props) => props.theme.Button.hover.color};
      transition: all 0.15s ease-in-out;
      cursor: pointer;
    }
  }

  &:focus {
    outline: 0;
  }

  &:active {
    outline: none;
  }

  &.inverted {
    background-color: ${(props) => props.theme.Button.inverted.backgroundColor};
    color: ${(props) => props.theme.Button.inverted.color};
    transition: all 0.15s ease-in-out;

    &:hover {
      transition: all 0.15s ease-in-out;
      background-color: transparent;
      color: ${(props) => props.theme.Button.inverted.hover.color};
    }
  }

  &.slim {
    width: auto;
    height: 21px;
  }

  &.bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &.fade-in {
    animation: ${fadeInAnimation} 0.5s ease-in-out;
  }

  &.fade-out {
    animation: ${fadeOut} 0.5s ease-in-out;
  }

  &.subscribed {
    color: ${(props) => props.theme.Button.subscribed.color};
    background-color: transparent;
    border-color: transparent;

    transition: color 0.15s ease-in-out;

    &:hover {
      transition: color 0.15s ease-in-out;
      color: ${(props) => props.theme.Button.subscribed.hover.color};
    }
  }
`;
