import { DefaultTheme } from "styled-components";

import Brands from "@/Brands";

import { ssoAppDefault } from "./ssoAppDefault";
import { weltTheme } from "./welt";

export const BrandThemes = {
  [Brands.Bild]: ssoAppDefault,
  [Brands.Aubi]: ssoAppDefault,
  [Brands.Cobi]: ssoAppDefault,
  [Brands.Welt]: weltTheme,
  [Brands.SSOAppDefault]: ssoAppDefault,
};

const getThemeForBrand = (brand: Brands): DefaultTheme => {
  const theme = BrandThemes[brand];
  if (!theme) {
    console.log("No theme found for brand", brand);
    console.log("Using default theme");
  }
  return theme;
};

export { getThemeForBrand };
