import { Colors, FontFamily } from "@/styles/styles";

export type ChipTheme = {
  background: {
    default: string;
    hover: string;
  };
  border: {
    default: string;
    hover: string;
  };
  borderRadius: string;
  color: {
    default: Colors;
    hover: Colors;
  };
  fontSize: string;
  lineHeight: string;
  fontFamily: FontFamily;
  padding: string;
};

const defaultChipTheme: ChipTheme = {
  background: {
    default: Colors.White,
    hover: Colors.DarkBlack,
  },
  border: {
    default: Colors.WeltLightGrey,
    hover: Colors.WeltLightGrey,
  },
  borderRadius: "20px",
  color: {
    default: Colors.DarkBlack,
    hover: Colors.White,
  },
  fontSize: "11px",
  lineHeight: "18px",
  fontFamily: FontFamily.MarkOTHeavy,
  padding: "5px 16px",
};

const weltChipTheme: ChipTheme = {
  ...defaultChipTheme,
  background: {
    default: Colors.White,
    hover: Colors.WeltLightGrey,
  },
  color: {
    default: Colors.WeltBlue,
    hover: Colors.WeltOrange,
  },
};

export { defaultChipTheme, weltChipTheme };
