import styled from "styled-components";

import { fadeInAnimation, pulse } from "@/styles/common";

export const StyledInformationWrapper = styled.span`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${fadeInAnimation} 0.5s ease-in-out;
`;

export const StyledInformationNote = styled.div`
  text-align: center;
  font-family: ${(props) => props.theme.SmartCTAButton.fontFamily};
  font-size: ${(props) => props.theme.SmartCTAButton.fontSize};
  line-height: ${(props) => props.theme.SmartCTAButton.lineHeight};
  color: ${(props) => props.theme.SmartCTAButton.color};
  text-transform: uppercase;
  margin-right: ${(props) => props.theme.SmartCTAButton.marginRight};

  &.pulse {
    animation: ${pulse} 1s infinite;
  }
`;
