import { Colors, FontFamily } from "@/styles/styles";

export type SmartCTAButtonTheme = {
  color: string;
  fontFamily: string;
  fontSize: string;
  lineHeight: string;
  marginRight: string;
};

const defaultSmartCTAButtonTheme: SmartCTAButtonTheme = {
  color: Colors.WeltBlue,
  fontFamily: FontFamily.MarkOTHeavy,
  fontSize: "14px",
  lineHeight: "18px",
  marginRight: "16px",
};

const weltSmartCTAButtonTheme: SmartCTAButtonTheme = {
  ...defaultSmartCTAButtonTheme,
};

export { defaultSmartCTAButtonTheme, weltSmartCTAButtonTheme };
