import { DefaultTheme } from "styled-components";

import { weltChipTheme } from "@/components/Chip/theme";
import { weltDialogTheme } from "@/components/Dialog/theme";
import { weltHeadlineTheme } from "@/components/Headline/theme";
import { weltLinkTheme } from "@/components/Link/theme";
import { weltNewsletterBannerTheme } from "@/components/NewsletterBanner/theme";
import { weltShowAllButtonTheme } from "@/components/ShowAllButton/theme";
import { weltSmartCTAButtonTheme } from "@/components/SmartCTAButton/theme";
import { weltButtonTheme } from "@/components/StyledButton/theme";
import { weltTopButtonTheme } from "@/components/TopButton/theme";
import { weltFeaturedNewsletterCardTheme } from "@/sections/FeaturedNewsletter/FeaturedNewsletterCard/theme";
import { weltHeaderNewsletterCardTheme } from "@/sections/Header/HeaderNewsletterCard/theme";
import { weltHeaderTheme } from "@/sections/Header/theme";
import { weltTopicNewsletterCardTheme } from "@/sections/TopicNewsletter/TopicNewsletterCard/theme";

import { weltGlobalTheme } from "./globalStyles";
import { ssoAppDefault } from "./ssoAppDefault";

const weltTheme: DefaultTheme = {
  ...ssoAppDefault,
  global: weltGlobalTheme,
  Header: weltHeaderTheme,
  Headline: weltHeadlineTheme,
  Link: weltLinkTheme,
  HeaderNewsletterCard: weltHeaderNewsletterCardTheme,
  TopicNewsletterCard: weltTopicNewsletterCardTheme,
  NewsletterBanner: weltNewsletterBannerTheme,
  Button: weltButtonTheme,
  ShowAllButton: weltShowAllButtonTheme,
  Chip: weltChipTheme,
  FeaturedNewsletterCard: weltFeaturedNewsletterCardTheme,
  SmartCTAButton: weltSmartCTAButtonTheme,
  TopButton: weltTopButtonTheme,
  Dialog: weltDialogTheme,
};

export { weltTheme };
