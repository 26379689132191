import styled from "styled-components";

export const StyledChip = styled.a`
  background: ${(props) => props.theme.Chip.background.default};
  border: 1px solid ${(props) => props.theme.Chip.border.default};
  border-radius: ${(props) => props.theme.Chip.borderRadius};
  text-decoration: none;
  text-transform: uppercase;
  color: ${(props) => props.theme.Chip.color.default};
  font-size: ${(props) => props.theme.Chip.fontSize};
  line-height: ${(props) => props.theme.Chip.lineHeight};
  font-family: ${(props) => props.theme.Chip.fontFamily};
  padding: ${(props) => props.theme.Chip.padding};
  transition: all 0.15s ease-in-out;

  :hover {
    background-color: ${(props) => props.theme.Chip.background.hover};
    border: 1px solid ${(props) => props.theme.Chip.border.hover};
    color: ${(props) => props.theme.Chip.color.hover};
    cursor: pointer;
    transition: all 0.15s ease-in-out;
  }
`;
