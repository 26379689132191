import styled from "styled-components";

export const StyledNewsletterBanner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 20px;

  background-color: ${(props) => props.theme.NewsletterBanner.backgroundColor};
  border-top-right-radius: ${(props) =>
    props.theme.NewsletterBanner.borderRadius};

  &.absolute-bottom-left {
    position: absolute;
    padding-right: 8px;
    bottom: -14px;
    left: -1px;
    height: 36px;
  }
`;

export const StyledSVGSpan = styled.div`
  display: flex;
  margin-right: ${(props) => props.theme.NewsletterBanner.marginRight};
`;

export const StyledNewsletterText = styled.span`
  text-transform: uppercase;

  color: ${(props) => props.theme.NewsletterBanner.color};
  line-height: ${(props) => props.theme.NewsletterBanner.lineHeight};
  font-size: ${(props) => props.theme.NewsletterBanner.fontSize};
  font-family: ${(props) => props.theme.NewsletterBanner.fontFamily};
`;
