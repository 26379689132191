import styled from "styled-components";

export const StyledLoadingAnimationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3;

  text-transform: uppercase;
  color: black;
  font-family: ${(props) => props.theme.global.fonts.fontFamily};
  font-size: 11px;
  line-height: 18px;
  margin: 25px;

  &.inverted {
    color: white;
  }
`;
