import { Colors, FontFamily } from "@/styles/styles";

export type ButtonTheme = {
  height: string;
  maxWidth: {
    desktop: string;
    mobile: string;
  };
  border: {
    width: string;
    color: string;
    style: string;
    radius: string;
  };
  color: string;
  fontFamily: string;
  fontSize: string;
  hover: {
    backgroundColor: string;
    color: string;
  };
  inverted: {
    backgroundColor: string;
    color: string;
    hover: {
      color: string;
    };
  };
  subscribed: {
    color: string;
    hover: {
      color: string;
    };
  };
};

const defaultButtonTheme: ButtonTheme = {
  height: "44px",
  maxWidth: {
    desktop: "280px;",
    mobile: "450px",
  },
  border: {
    width: "1px",
    color: Colors.Black,
    style: "solid",
    radius: "3px",
  },
  color: Colors.Black,
  fontFamily: FontFamily.MarkOTHeavy,
  fontSize: "14px",

  hover: {
    backgroundColor: Colors.Black,
    color: Colors.White,
  },
  inverted: {
    backgroundColor: Colors.Black,
    color: Colors.White,
    hover: {
      color: Colors.Black,
    },
  },
  subscribed: {
    color: Colors.Black,
    hover: {
      color: Colors.White,
    },
  },
};

const weltButtonTheme: ButtonTheme = {
  ...defaultButtonTheme,
  border: {
    ...defaultButtonTheme.border,
    color: Colors.WeltOrange,
  },
  color: Colors.WeltOrange,
  fontFamily: FontFamily.MarkOTHeavy,

  hover: {
    backgroundColor: Colors.WeltOrange,
    color: Colors.White,
  },
  inverted: {
    backgroundColor: Colors.WeltOrange,
    color: Colors.White,
    hover: {
      color: Colors.WeltOrange,
    },
  },
  subscribed: {
    color: Colors.WeltBlue,
    hover: {
      color: Colors.WeltOrange,
    },
  },
};

export { defaultButtonTheme, weltButtonTheme };
