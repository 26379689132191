import { Colors, FontFamily } from "@/styles/styles";

export type TopicNewsletterCardTheme = {
  borderColor: string;
  transmissionTime: {
    fontSize: string;
    lineHeight: string;
    textTransform: string;
  };
  intro: {
    fontFamily: string;
    fontSize: string;
    lineHeight: string;
  };
  title: {
    fontFamily: string;
    fontSize: {
      mobile: string;
      desktop: string;
    };
    lineHeight: string;
  };
};

const defaultTopicNewsletterCardTheme: TopicNewsletterCardTheme = {
  borderColor: Colors.Black,
  transmissionTime: {
    fontSize: "18px",
    lineHeight: "23px",
    textTransform: "normal",
  },
  intro: {
    fontFamily: "Arial",
    fontSize: "18px",
    lineHeight: "23px",
  },
  title: {
    fontFamily: "Arial",
    fontSize: {
      mobile: "18px",
      desktop: "21px",
    },
    lineHeight: "23px",
  },
};

const weltTopicNewsletterCardTheme: TopicNewsletterCardTheme = {
  ...defaultTopicNewsletterCardTheme,
  borderColor: Colors.WeltLightGrey,
  transmissionTime: {
    fontSize: "11px",
    lineHeight: "14px",
    textTransform: "uppercase",
  },
  intro: {
    fontFamily: FontFamily.MarkOTRegular,
    fontSize: "14px",
    lineHeight: "21px",
  },
  title: {
    fontFamily: FontFamily.MarkOTHeavy,
    fontSize: {
      mobile: "14px",
      desktop: "16px",
    },
    lineHeight: "21px",
  },
};

export { defaultTopicNewsletterCardTheme, weltTopicNewsletterCardTheme };
