import { Colors, FontFamily } from "@/styles/styles";

export type HeaderNewsletterCardTheme = {
  color: string;
  backgroundColor: string;
  title: {
    fontFamily: string;
    fontSize: string;
    color: string;
    lineHeight: string;
  };
  transmissionTime: {
    fontFamily: string;
    fontSize: string;
    color: string;
    lineHeight: string;
  };
};

const defaultHeaderNewsletterCardTheme: HeaderNewsletterCardTheme = {
  color: Colors.Black,
  backgroundColor: Colors.White,
  title: {
    color: Colors.Black,
    fontFamily: "Arial",
    fontSize: "25px",
    lineHeight: "27px",
  },
  transmissionTime: {
    color: Colors.Black,
    fontFamily: "Arial",
    fontSize: "14px",
    lineHeight: "18px",
  },
};

const weltHeaderNewsletterCardTheme: HeaderNewsletterCardTheme = {
  ...defaultHeaderNewsletterCardTheme,
  color: Colors.White,
  backgroundColor: Colors.White,
  title: {
    color: Colors.Black,
    fontFamily: FontFamily.MarkOTHeavy,
    fontSize: "14px",
    lineHeight: "18px",
  },
  transmissionTime: {
    color: Colors.Black,
    fontFamily: FontFamily.MarkOTRegular,
    fontSize: "11px",
    lineHeight: "18px",
  },
};

export { defaultHeaderNewsletterCardTheme, weltHeaderNewsletterCardTheme };
