import styled from "styled-components";

export const StyledLink = styled.a`
  color: ${(props) => props.theme.Link.color} !important;
  font-family: ${(props) => props.theme.Link.fontFamily.default};
  font-size: ${(props) => props.theme.Link.fontFamily.default};
  line-height: ${(props) => props.theme.Link.lineHeight.default};

  &:hover {
    cursor: pointer;
  }

  &.small {
    font-weight: ${(props) => props.theme.Link.fontFamily.small};
    font-size: ${(props) => props.theme.Link.fontSize.small};
    line-height: ${(props) => props.theme.Link.lineHeight.small};
  }

  &.underlined {
    text-decoration: underline;
  }
`;
