import { Colors, FontFamily } from "@/styles/styles";

export type HeadlineTheme = {
  padding: {
    medium: string;
    xl: string;
  };
  color: Colors;
  fontFamily: FontFamily;
  fontSize: string;
  lineHeight: string;
};

const defaultHeadlineTheme: HeadlineTheme = {
  padding: {
    medium: "16px",
    xl: "20px",
  },
  color: Colors.DarkBlack,
  fontFamily: FontFamily.MarkOTHeavy,
  fontSize: "20px",
  lineHeight: "26px",
};

const weltHeadlineTheme: HeadlineTheme = {
  ...defaultHeadlineTheme,
};

export { defaultHeadlineTheme, weltHeadlineTheme };
